@import url("https://fonts.googleapis.com/css?family=Montserrat|Montserrat:b|Montserrat:i");

:root {
    --text-white: #f4f4f4;
    --bg: #151515;
    --container: #1d1d1d;
    --accent-purple: #cd40ff;
    --accent-green: #00ff2e;
    --accent-gold: #ecba41;

    font-size: 16px;
}
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    min-height: 100vh;
    font-family: "Montserrat", sans-serif;
    background-color: var(--bg);
}
main {
    padding: 2rem;
    text-align: center;
    @media screen and (min-width: 1030px) {
        text-align: left;
    }
    h1 {
        color: var(--text-white);
        margin-bottom: 2rem;
    }
    .app--grid {
        display: flex;
        flex-wrap: wrap;
        row-gap: 2rem;
        column-gap: 2rem;
        margin-bottom: 2rem;
        justify-content: center;
        @media screen and (min-width: 1030px) {
            justify-content: initial;

        }

        &--item {
            max-width: 100%;
            width: 300px;
            overflow: hidden;
            background-color: var(--container);
            color: var(--text-white);
            text-decoration: none;
            display: grid;
            grid-template-rows: 170px 1fr;

            .imgWrapper {
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .contentWrapper {
                padding: 1rem;
                text-align: center;
                display: grid;
                row-gap: 1rem;
                grid-template-rows: auto auto 1fr;
                // background: linear-gradient(308.73deg, var(--accent-purple) 0%, var(--container) 14.99%);
                // height: 100%;

                position: relative;
                &::after {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 2px;
                    left: 0;
                    top: 0;
                    background: var(--accent-purple);
                }
                .title {
                    font-weight: bold;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                }
                .access {
                    font-size: 0.9rem;
                }
            }
        }

        &.server {
            .contentWrapper {
                &::after {
                    background: var(--accent-green);
                }
            }
        }
        &.websites {
            .contentWrapper {
                &::after {
                    background: var(--accent-gold);
                }
            }
        }
    }
}
